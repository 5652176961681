import { FC, useEffect, useState } from "react";

/* Metronic */
import { ILayout, useLayout } from "../../vendor/_metronic/layout/core";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";

import clsx from "clsx";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
import { logout } from "../../utils/authRequest";
import { apiCall } from "../../utils/serviceCall";
import {
  HTTPMethod,
  getListOfIdFromIri,
  getNotification,
  hydra,
  parseDashboardDetailsPath,
  takeIdFromIri,
} from "../../utils/helper";
import Notification from "../Notification/Notification";
import { UserImage } from "../UserImage/UserImage";
import { useDispatch } from "react-redux";
import Alarms, { DisplayAlarms } from "../alarms/Alarms";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import moment from "moment";
import { NotificationReducerTypeActions } from "../../redux/stores/notification/notificationReducer";
import NotificationIcon from "../NotificationIcon/NotificationIcon";

const Header: FC<any> = ({ alarmData }) => {
  const { config } = useLayout();
  const { reloadAlarms, showAlarms, showIconAlarms } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  useEffect(() => {
    updateDOM(config);
  }, [config]);
  const { pathname } = useLocation();

  const { userData } = useSelector(
    (state: CombineReducersState) => state.userReducer
  );

  const [alarmsLoading, setAlarmsLoading] = useState<boolean>(true);
  const intl = useIntl();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [alarms, setAlarms] = useState<any>(null);
  const [court, setCourts] = useState<any>(null);
  const [curators, setCurators] = useState<any>(null);
  const [companyCurators, setCompanyCurators] = useState<any>(null);
  const location = useLocation();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const dispatch = useDispatch();
  const setShowAlarms = (value) => {
    dispatch({
      type: NotificationReducerTypeActions.SET_NOTIFICATION,
      payload: { showAlarms: value },
    });
  };
  const toggleNotification = (value) => {
    setShowNotification(value);
  };

  // const [allNotification, setAllNotification] = useState<typeOfObject[]>([])
  async function takeCourts(filter) {
    await apiCall(HTTPMethod.GET, `/courts`, { params: { id: filter } }).then(
      async (response) => {
        const listCourts = response.data["hydra:member"];
        setCourts(listCourts);
      }
    );
  }
  async function takeCuratorsFromCompanyCurators(filter) {
    if(!filter || filter.length == 0) return;
    await apiCall(
      HTTPMethod.GET,
      `/curator_companies?page=1&itemsPerPage=1000`,
      { params: { id: filter } }
    ).then(async (response) => {
      const listCompaniesCurators = response.data["hydra:member"];

      setCompanyCurators(listCompaniesCurators);
      let listOfCuratorsId = takeIdFromIri(
        getListOfIdFromIri(listCompaniesCurators.map((el) => el.curator))
      );

      await apiCall(HTTPMethod.GET, `/curators?page=1&itemsPerPage=1000`, {
        params: { id: listOfCuratorsId },
      }).then((response) => {
        const listCurators = response.data["hydra:member"];
        setCurators(listCurators);
      });
    });
  }
  const getAlarms = (page = 1) => {
    if (!alarmsLoading) {
      setAlarmsLoading(true);
    }
    const params = {
      // "companyGroups.state": "processing",
      // page: page,
      // itemsPerPage: 100,
      // "companiesUser.alert[before]": moment(new Date()).format("YYYY-MM-DD"),
    };
    apiCall(HTTPMethod.GET, `/companies/alerts`, { params: params })
      .then(async (response) => {
        const data = response.data;
        let listOfCompanyCuratorsId = takeIdFromIri(
          getListOfIdFromIri(
            data["hydra:member"].map((el) => el.companyCurators)
          )
        );
        let listOfCourtsId = takeIdFromIri(
          data["hydra:member"].map((el) => el.court)
        );
        await takeCourts(listOfCourtsId);
        await takeCuratorsFromCompanyCurators(listOfCompanyCuratorsId);
        // console.log(data,"data alerts");
        setAlarms(data);
      })
      .finally(() => setAlarmsLoading(false));
  };
  useEffect(() => {
    getAlarms();
  }, [reloadAlarms]);
  useEffect(() => {
    if (alarms && curators && companyCurators && court) {
      dispatch({
        type: NotificationReducerTypeActions.SET_NOTIFICATION,
        payload: { showIconAlarms: true },
      });
    } else {
      dispatch({
        type: NotificationReducerTypeActions.SET_NOTIFICATION,
        payload: { showIconAlarms: false },
      });
    }
  }, [alarms, curators, companyCurators, court]);
  useEffect(() => {
    getNotification();
    const notificationInterval = setInterval(() => {
      getNotification();
    }, 10000);
    getAlarms();
    const alarmInterval = setInterval(() => {
      getAlarms();
    }, 60000); // 1 minute
    return () => {
        clearInterval(notificationInterval);
        clearInterval(alarmInterval);
    };
  }, [location]);
  return (
    <div
      className="
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
        justify-content-end
    "
      id="kt_app_header_menu"
      data-kt-menu="true"
    >
      <NotificationIcon
        style={{
          textAlign: "center",
          position: "relative",

          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
        showNotification={showNotification}
        toggleNotification={toggleNotification}
      ></NotificationIcon>
      <div
        onClick={() => showIconAlarms && setShowAlarms(!showAlarms)}
        className={`btn btn-icon btn-custom btn-icon-muted ${
          showAlarms ? "active" : ""
        }`}
        style={{
          position: "relative",
          right: "5px",
          top: "12px",
          cursor: "pointer",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
        id="kt_notifications_toggle"
      >
        <i
          className="ki-duotone ki-notification-status fs-2"
          style={{ color: "#fcbd00" }}
        >
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
        </i>
        {alarms && alarms[hydra.MEMBER].length > 0 && (
          <span
            style={{
              fontSize: "10px",
              position: "absolute",
              // padding: "2px",
              margin: "0 auto ",
              top: "45%",
              bottom: "23%",
              right: "3%",
              maxHeight: "17px",
              minWidth: "17px",
              lineHeight: "12px",
            }}
            className="badge badge-pill badge-danger"
          >
            {alarms[hydra.MEMBER].length}
          </span>
        )}
      </div>

      {userData && (
        <div className=" app-navbar-item d-flex gap-2 align-items-center mr-0">
          <div
            style={{
              display: "flex",
              gap: "5px",
              color: "#404040",
              textAlign: "right",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            <p className="mb-0">{userData.firstName}</p>
            <p className="mb-0"> {userData.lastName}</p>
          </div>
          <div onClick={() => setShowMenu(!showMenu)}>
            <UserImage
              credential={{
                name: userData.firstName,
                surname: userData.lastName,
              }}
            ></UserImage>
          </div>
          <div className={clsx("app-navbar-item")}>
            <div
              className={clsx(
                "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px",
                showMenu ? "show" : ""
              )}
              data-kt-menu="true"
              style={{
                zIndex: 105,
                position: "fixed",
                inset: "0px 0px auto auto",
                margin: "0px",
                transform: "translate3d(-30px, 69px, 0px)",
              }}
            >
              {/* <Languages /> */}
              <div className="menu-item" onClick={() => logout()}>
                <a className="menu-link px-5 ">
                  {intl.formatMessage({ id: "MENU.LOGOUT" })}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <Notification
        showNotification={showNotification}
        toggleNotification={toggleNotification}
      />
      {showAlarms &&
        DisplayAlarms({
          ...alarmData,
          courts: court,
          loading: alarmsLoading,
          companyCurators: companyCurators,
          curators: curators,
          data: alarms,
          // onChangePage: (page) => getAlarms(page),
          closeAlarms: () => setShowAlarms(false),
        })}
    </div>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute("data-kt-app-header-fixed", "true");
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute("data-kt-app-header-fixed-mobile", "true");
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute("data-kt-app-header-stacked", "true");
  }

  const appHeaderDefaultStickyEnabled =
    config.app?.header?.default?.sticky?.enabled;
  let appHeaderDefaultStickyAttributes: { [attrName: string]: string } = {};
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky
      ?.attributes as {
      [attrName: string]: string;
    };
  }

  const appHeaderDefaultMinimizeEnabled =
    config.app?.header?.default?.minimize?.enabled;
  let appHeaderDefaultMinimizeAttributes: { [attrName: string]: string } = {};
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize
      ?.attributes as {
      [attrName: string]: string;
    };
  }

  setTimeout(() => {
    const headerElement = document.getElementById("kt_app_header");
    // header
    if (headerElement) {
      const headerAttributes = headerElement
        .getAttributeNames()
        .filter((t) => t.indexOf("data-") > -1);
      headerAttributes.forEach((attr) => headerElement.removeAttribute(attr));

      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(
              key,
              appHeaderDefaultStickyAttributes[key]
            );
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(
              key,
              appHeaderDefaultMinimizeAttributes[key]
            );
          }
        }
      }
    }
  }, 0);
};

export { Header };
