import axios from "axios";
import { HTTPMethod } from "./helper";
import axiosApi from "./guard";

const API_URL = process.env.REACT_APP_API_URL;

export async function getDocJson(): Promise<apiDoc> {
  const response = await axios[HTTPMethod.GET](API_URL + '/docs.json', {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.data;
}

export function getDocJsonLD() {
  return apiCall(HTTPMethod.GET, '/docs.jsonld');
}

export async function apiCall(method: HTTPMethod, path: string, data?: any, cache = false) {



  let http = axiosApi(cache);

  if (method === HTTPMethod.GET) {
    return await http[method](`${API_URL}${path}`, {...data });
  } else {
    return await http[method](`${API_URL}${path}`, JSON.stringify(data));
  }
}
export async function apiCallFile(method: HTTPMethod, path: string, params?: any) {
  let http = axiosApi();
  return await http[method](`${API_URL}${path}`, params, {
    headers: {
      'accept': 'application/ld+json',
      'Content-Type': 'multipart/form-data'
    }
  });
}
