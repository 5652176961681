import clsx from "clsx"
import { FC } from "react"

interface UserImageInterface {
    credential: {
        name: string,
        surname: string
    },
    className?: string
}
export const UserImage: FC<UserImageInterface> = ({ credential, className = "" }) => {



    return <div className={clsx('symbol symbol-45px symbol-circle', className)}>
        <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder cursor-pointer">
            {credential.name.charAt(0)}
            {credential.surname.charAt(0)}
        </span>
    </div>

}
